<template>
  <div id="modal-message">
    <v-dialog v-model="dialog" persistent max-width="900" class="overflow-hidden" style="overflow: ">
      <v-card>
        <v-card-title class="blue darken-3 text-white" style="
            padding-left: 6px !important;
            position: sticky;
            top: 0;
            z-index: 999;
          ">
          <div class="d-flex justify-content-between flex-nowrap align-center w-100">
            <div class="text-h6">{{ email.data.subject || "Sin asunto" }}</div>
            <div class="text-h6 pl-4" @click="close" style="cursor: pointer">
              x
            </div>
          </div>
          <div>
            <span class="text-caption">
              De: {{ email.data.from.name || email.data.from.mailbox }}
              <v-icon id="menu-activator" class="mr-2 text--white">mdi-menu-down</v-icon>
            </span>

            <v-menu activator="#menu-activator">
              <v-list style="width: auto !important" class="px-2">
                <div class="d-flex">
                  <div style="width: 120px; text-align: right">De: &nbsp;</div>
                  <span>
                    <b>{{ email.data.from.name }} </b>{{ `<${email.data.from.mailbox}${email.data.from.host}>` }}
                  </span>
                </div>
                <div class="d-flex">
                  <div style="width: 120px; text-align: right">
                    Responser a: &nbsp;
                  </div>
                  <span>
                    {{ email.data.from.name }} &nbsp;
                    {{ `<${email.data.from.mailbox}${email.data.from.host}>` }}
                  </span>
                </div>
                <div class="d-flex">
                  <div style="width: 120px; text-align: right">
                    Para: &nbsp;
                  </div>
                  <span>
                    {{ `${email.data.from.mailbox}${email.data.from.host}` }}
                  </span>
                </div>
                <div class="d-flex">
                  <div style="width: 120px; text-align: right">
                    fecha: &nbsp;
                  </div>
                  <span>
                    {{ format_date() }}
                  </span>
                </div>
              </v-list>
            </v-menu>
          </div>
        </v-card-title>
        <v-card-text>
          <div id="messageBody"></div>
        </v-card-text>
        <v-card-actions>
          <div id="messageFooter"></div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ModalForm :visible="loader.estado" @close="loader.estado = false" :message="loader.message"></ModalForm>
  </div>
</template>
    

<script>
import ModalForm from "../modal/ModalForm.vue";

export default {
  components: {
    ModalForm,
  },
  props: {
    status: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: {},
    },
    flag: {
      type: String,
      default: null,
    },
    account: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      dialog: false,
      loader: {
        estado: false,
        message: "",
      },
      email: {
        message: {},
        body: {},
        data: {
          to: {},
          from: {},
        },
      },
    };
  },
  watch: {
    status: function (val) {
      if (val) this.get_message();
      else this.dialog = false;
    },
  },
  methods: {
    init_data() {
      this.email.data = {
        subject: this.text_capitalize(this.item?.envelope?.subject || ""),
        to: this.item?.envelope?.to[0] || "",
        from: this.item?.envelope?.from[0] || "",
      };
    },
    format_date() {
      let startTimeISOString = this.item?.envelope?.date || "";

      var startTime = new Date(startTimeISOString);
      startTime = new Date(
        startTime.getTime() + startTime.getTimezoneOffset() * 60000
      );

      return startTime.toLocaleString("es-CO");
    },
    get_message() {
      this.init_data();

      this.dialog = true;
      let item = this.item;
      this.loader.estado = true;
      this.loader.message =
        "Por favor espere mientras se consultan los datos..";

      this.axios
        .get(`/imap/messages/${item.uid}`, {
          params: {
            flag: this.flag,
            email_id: this.account?._id || "",
          },
        })
        .then((response) => {
          let data = response.data.data;

          if (!Array.isArray(data.struct)) data.struct = [data.struct];

          this.email.message = data;
          if (!Array.isArray(data.struct)) return;
          this.fix_data();
          this.$emit("seen", item);
        })
        .catch((error) => {
          console.log("Error: ", error);
          this.loader.estado = false;
        });
    },
    async fix_data() {
      try {
        let {
          message: { struct, uid, texthtml },
        } = this.email;

        let div = document.createElement("div");
        div.innerHTML = texthtml;
        document.getElementById("messageBody").appendChild(div);

        let parts = await this.order_parts(struct, []);

        if (parts.length > 0) {
          let span = document.createElement("span");
          span.textContent = `${parts.length} Archivos adjuntos`;
          span.className = "text-subtitle-1 font-weight-bold";
          document.getElementById("messageFooter").appendChild(span);
        }

        for await (const item of parts) {
          let response = await this.axios.get(
            `/imap/messages/${uid}/download`,
            {
              params: {
                part: item.partID,
                flag: this.flag,
                email_id: this.account?._id || ""
              },
              responseType: "arraybuffer",
            }
          );

          let { filename } = response.headers;
          let content = response.headers["content-type"];
          let buffer = null;

          if (content.match("image") || content.match("pdf")) {
            buffer = new Uint8Array(response.data).buffer;
          } else buffer = new Uint16Array(response.data).buffer;

          let blob = new Blob([buffer], { type: content });
          const fileURL = window.URL.createObjectURL(blob);

          let alink = document.createElement("a");
          let div = document.createElement("div");
          alink.className = "text-caption";
          alink.href = fileURL;
          alink.download = filename;
          alink.textContent = filename;

          div.appendChild(alink);
          document.getElementById("messageFooter").appendChild(div);
        }

        this.loader.estado = false;
      } catch (error) {
        console.log("Error: ", error);
        this.loader.estado = false;
      }
    },

    text_capitalize(text) {
      text = String(text).toLowerCase() || "";

      return String(text).charAt(0).toUpperCase() + String(text).slice(1);
    },

    async order_parts(struct, attachments) {
      attachments = attachments || [];

      struct.forEach((i) => {
        if (Array.isArray(i)) this.order_parts(i, attachments);
        else if (
          i.disposition &&
          ["ATTACHMENT"].indexOf(String(i.disposition.type).toUpperCase()) > -1
        ) {
          attachments.push(i);
        }
      });

      return attachments;
    },
    close() {
      document.getElementById("messageBody").innerHTML = "";
      document.getElementById("messageFooter").innerHTML = "";
      this.$emit("close");
    },
  },
};
</script>

<style>
/* .v-dialog .v-card__text {
  padding: 0 !important;
} */

.v-dialog .v-card__text #messageBody {
  display: flex;
  justify-content: center;
}

.v-dialog>.v-card>.v-toolbar {
  position: sticky;
  top: 0;
  z-index: 999;
}

#menu-activator {
  color: white;
  margin-right: 0 !important;
  font-size: 18px;
}

.v-menu__content .v-sheet {
  width: 35vh !important;
}
</style>