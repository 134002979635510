<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent width="450px">
            <v-card class="modalAccounts">
                <v-card-title class="text-h5">
                    Seleccione la cuenta de correo
                </v-card-title>

                <v-divider class="my-1"></v-divider>

                <v-card-text>
                    <v-radio-group v-model="select">
                        <v-radio v-for="item in data" :label="item.inb_user" :value="item._id" :key="item._id">
                        </v-radio>
                    </v-radio-group>
                </v-card-text>

                <v-divider class="my-1"></v-divider>

                <v-card-actions class="flex justify-content-end">
                  <v-btn class="green darken-1 white--text" @click="validate">
                    Aceptar
                  </v-btn>
                  <v-btn class="red darken-1 white--text" @click="close">
                    Cancelar
                  </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
export default {
    props: {
        status: {
            type: Boolean,
            default: false,
        },
        data: {
            type: Array,
            default: []
        },
        account: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            dialog: false,
            select: null
        }
    },
    watch: {
        status: function (val) {
            this.select = this.account?._id || null
            this.dialog = val;
        },
    },
    methods: {
        validate() {
            if (!this.select) return alert("Debe selecionar un cuenta")

            this.$emit("callback", this.select)
        },
        close() {
            this.$emit("close");
        },
    }
}
</script>

<style>
.modalAccounts .v-card__title {
    padding-left: 12px !important
}

.modalAccounts .v-card__text {
    padding-left: 12px !important;
    padding-bottom: 0px !important;
}

.modalAccounts .v-label {
    margin-bottom: 0 !important;
}

.modalAccounts .v-radio {
    margin-bottom: 10px !important;
}
</style>